@import "../styles/variables.scss";
form {
    margin-bottom: 60px;
}
p {
    label {
        display: block;
        font-size: 1.2rem;
        line-height: 2.5rem;
        color: $grey-med;
        font-family: $sans-condensed;
        font-weight: 700;
    }
}
input,
textarea,
.submit-button {
    border: none;
    background-color: $off-white;
    font-size: 2rem;
    line-height: 2rem;
    padding: 10px 20px;
    width: 100%;
}
.submit-button {
    background-color: $red;

    color: white;
}
