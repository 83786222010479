@import "../styles/bourbon/bourbon";
@import "../styles/variables.scss";
.wd-hero {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: -150px;
    z-index: 3;

    &:before {
        height: 20%;
        bottom: 8.5%;
        @include transform(skewY(-4deg));
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        background: red;
    }
    &:after {
        height: 45%;
        bottom: -19.5%;
        @include transform(skewY(-3deg));
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 2;
        background: white;
    }
    .logo {
        width: 90%;
        height: 100%;
        background-position: right 130px;
        background-size: 340px;
        background-repeat: no-repeat;
        z-index: 2;
        position: relative;
    }
    .tv {
        position: absolute;
        left: 0;
        top: 130px;
        z-index: 4;
        img {
            max-width: 100%;
            width: 600px;
        }
    }
    .front {
        z-index: 3;
        position: relative;
        max-height: 550px;
        top: 20px;
    }
    .outer-container {
        height: 100%;
        h1,
        h1 span {
            color: white;
        }
    }
    .hero {
        position: absolute;
        z-index: 3;
        top: 0;
    }
}
.work-detail {
    .technology-list {
        li {
            display: inline-block;
            background-color: $red;
            padding: 2px 10px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 5px;
        }
    }

    .social {
        overflow: hidden;
        iframe {
            width: 320px !important;
            display: block !important;
            float: left;
            margin: 10px !important;
        }
    }

    img {
        max-width: 100%;
        width: auto;
    }
}
.gallery-list {
    ul {
        list-style: none;
        margin: 0;
        li {
            @include span-columns(10);
            @include shift(1);
            margin-bottom: 80px;
            position: relative;
            &:nth-child(2n) {
                .caption {
                    float: right;
                    @include omega();
                    padding-left: 2%;
                    right: 0;
                    left: initial;
                    &:before {
                        left: -10px;
                    }
                }
                img {
                    float: left;
                }
            }
        }
    }
    .caption {
        @include span-columns(4);
        padding: 20px;
        @extend %mid;
        margin: 10px;
        background-color: $grey-med;
        left: 0;
        h3 {
            color: white;
        }
        p {
            position: relative;
            z-index: 3;
        }
        &:before {
            content: " ";
            width: 25px;
            height: 25px;
            display: block;
            @include position(absolute);
            background-color: $grey-med;
            right: -10px;
            @extend %mid;
            @include transform(rotate(45deg));
        }
    }
    img {
        @include span-columns(8);
        @include omega();
        float: right;
    }
}
h2 {
    margin-bottom: 20px;
}
.pattern {
    background-image: url("../images/bg-pattern.svg");
    background-size: cover;
    @include position(absolute, 0 0 0 0);
}

.featured {
    padding: 60px 0;
    overflow: initial;
    position: relative;
    z-index: 4;
    background-color: black;
    @include perspective(1000px);
    min-height: 400px;
    &:before {
        @include transform(skewY(6deg));
        height: 45%;
        top: -21.5%;
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        background: white;
    }
    &:after {
        height: 15%;
        bottom: -9.5%;
        @include transform(skewY(2deg));
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        background: white;
    }
    .one-col {
    }
    .content {
        @include span-columns(5);
        color: white;
        h3 {
            color: white;
        }
    }
    .image {
        @include position(absolute, 0 0 0 0);
        z-index: 6;
        @include transform(
            translate3d(600px, 0, -610px) rotateY(-50deg) scale(1.2)
        );
    }
}
.website {
    @include position(absolute, 0 0 auto auto);
    display: block;
    background-color: $red;
    font-size: 25px;
    padding: 10px 30px;
    margin: 60px 60px 0 0;
    color: white;
    z-index: 10;
    text-decoration: none;
}
.video {
    h2,
    h3 {
        color: white;
        padding: 60px 0;
        text-align: center;
    }
}
.social {
    padding-top: 60px;
    h2 {
        text-align: center;
        margin: 40px 0;
    }
    background-color: lightblue;
    .quotes {
        list-style: none;
        li {
            background-color: #fefefe;
            padding: 20px;
            @include span-columns(4);
            height: 300px;
            &:nth-child(2n) {
                background-color: $grey-dark;
            }
        }
        .quote {
            font-family: "Bookman", Georgia, "Times New Roman", serif;
            font-style: italic;
            font-size: 20px;
            line-height: 1.5;
        }
        cite {
            color: $red;
        }
    }
}
.stats {
    h2 {
        text-align: center;
    }
    li {
        @include span-columns(3);
        text-align: center;
        font-size: 14px;
        font-family: $sans-condensed;
        font-weight: 300;
        color: $grey-light;
        > span {
            color: $red;
            font-family: $sans-condensed;
            font-weight: 700;
            font-size: 60px;
            display: block;
        }
    }
}

.press {
    ul {
        background-color: rgba(255, 255, 255, 0.7);
        border-top: 4px solid $red;
        border-bottom: 4px solid $red;
        list-style: none;
        padding: 60px;
        li {
            padding: 10px 0px;
        }
    }
}
.results {
    background-color: rgba(255, 255, 255, 0.7);
    border-top: 4px solid $red;
    border-bottom: 4px solid $red;
}
.gallery {
    h2 {
        position: relative;
        z-index: 2;
        text-align: center;
        padding: 60px;
    }
    ul {
        li {
            @include span-columns(4);
            position: relative;
            height: 869px;
            padding: 10px;
            h3 {
            }
            img {
                @include position(absolute, 0 0 0 0);
                width: 95%;
            }
        }
        &.four-col {
            li {
                @include span-columns(3);
            }
        }
    }
}
