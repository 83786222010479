@import "../styles/bourbon/bourbon";
@import "../styles/variables.scss";
.about {
    &.profile {
        background-image: url("../images/jeffboulay.jpg");
        @include filter(contrast(90%) brightness(70%));
        @include animation(fade-from-black 2s ease-out);
        width: 50%;
        height: 100%;
        position: fixed;
        background-size: cover;
        z-index: 10;
    }
    > section,
    .callout {
        width: 50%;
        margin-left: 50%;
    }
    h3 {
        text-transform: uppercase;
        color: $red;
        font-size: 2rem;
    }
    h4 {
        color: $grey-light;
        font-family: $sans-condensed;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 1.5rem;
    }
    ul {
        list-style: none;
    }
}
.clients {
    @include outer-container;
}
.client-list {
    li {
        @include span-columns(3);
        //border-right: 1px solid #ccc;
        //border-bottom:2px solid #666 ;
        img {
            max-width: 100%;
            width: auto;
            border: 1px solid #efefef;
        }
        &:nth-child(4n) {
            @include omega();
            border-right: none;
        }
    }
}
.history {
    background-color: #fff;
    p {
        margin: 60px;
        @include columns(2 8em);
        @include column-gap(4em);
    }
}

@include keyframes(fade-from-black) {
    0%,
    30% {
        @include filter(contrast(0%) brightness(0%));
    }
    50%,
    60% {
        @include filter(contrast(100%) brightness(120%));
    }
    100% {
        @include filter(contrast(90%) brightness(70%));
    }
}
