@import "../styles/variables.scss";
.main-footer {
    //@include position(absolute, 0 0 0px 0 )
    background-color: $grey-dark;
    position: relative;
    height: 50px;
    width: 100%;
    @media screen and (min-width: $break-medium) {
        height: 100px;
    }

    nav {
        overflow: hidden;
        @include position(absolute, 0 0px 0 auto);
    }
    h4 {
        color: white;
        font-weight: 100;
        float: left;
        line-height: 50px;
        margin-right: 10px;
        @media screen and (min-width: $break-medium) {
            line-height: 100px;
        }
    }
    ul {
        list-style: none;
        display: inline;
        overflow: hidden;
        li {
            display: block;
            float: left;
            &:nth-child(1) a {
                background-color: lighten($grey-dark, 5%);
            }
            &:nth-child(2) a {
                background-color: lighten($grey-dark, 10%);
            }
            &:nth-child(3) a {
                background-color: lighten($grey-dark, 15%);
            }
            &:nth-child(4) a {
                background-color: lighten($grey-dark, 20%);
            }
            &:nth-child(5) a {
                background-color: lighten($grey-dark, 25%);
            }
            a {
                display: block;
                overflow: hidden;
                width: 40px;
                height: 40px;
                background-size: contain;
                @include transition(all 0.5s ease-in);
                @media screen and (min-width: $break-medium) {
                    width: 100px;
                    height: 100px;
                }
                &:hover {
                    background: $red;
                }
                img {
                    width: 100%;
                    padding: 10%;
                }
            }
        }
    }
}
