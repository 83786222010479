.ddsharkweek {
    section:nth-child(2) {
        h2 {
            color: white;
        }
        padding: 4% 0;
        background-image: url("../../images/ddsharkweek/ddsharkweek.jpg");
        background-size: cover;
        //background-attachment: fixed;
    }
}
.wd-hero {
    .xbox {
        position: absolute;
        right: 140px;
        bottom: 60px;
        z-index: 4;
    }
}
