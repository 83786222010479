@import "./bourbon/bourbon";
@import "./neat/neat";
//colors
$red: #cb2829;
$red-dark: #240407;
$off-white: #e5e5e5;
$grey-light: #6a6162;
$grey-med: #333;
$grey-dark: #151314;

$break-small: 320px;
$break-medium: 768px;
$break-large: 1200px;
// %grey-gradient {
//     // needs latest Compass, add '@import "compass"' to your scss
//     background-color: #666666; // Old browsers
//     //@include filter-gradient($grey-light, $grey-dark, vertical); // IE6-9
//     @include background-image(
//         linear-gradient(top, $grey-light 0%, $grey-dark 100%)
//     );
// }

// %red-gradient {
//     // needs latest Compass, add '@import "compass"' to your scss
//     background-color: $red; // Old browsers
//     //@include filter-gradient($red, $red-dark, vertical); // IE6-9
//     @include background-image(linear-gradient(top, $red 0%, $red-dark 100%));
// }

/*fonts*/

$sans-condensed: "Open Sans Condensed", sans-serif;

/*grid*/
$grid-columns: 12;
$width: 1200px;
$max-width: 1200px;
$font-size: 10;
$paragraph-size: 1.6;

%mid {
    @include position(absolute, 50% auto auto auto);
    @include transform(translateY(-50%));
}
%abs-mid {
    @include position(absolute, 50% auto auto 50%);
    @include transform(translateY(-50%) translateX(-50%));
}
