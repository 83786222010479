@import "./variables.scss";
body {
    font-family: $sans-condensed;
    font-weight: 300;
    //color: white;
    background: url("../images/paper.png") center top repeat-x;

    background-attachment: fixed;
}

p {
    margin: 0 0 1em 0;
    line-height: 2rem;
    font-size: 1.2rem;
    font-family: $sans-condensed;
    font-weight: 300;
    color: #a9a9a9;
}
.main-view {
    position: absolute;
    width: 100%;
    &.ng-enter,
    &.ng-leave {
        @include transition(1.5s linear all);
    }
    &.ng-enter {
        opacity: 0;
        &.ng-enter-active {
            opacity: 1;
        }
    }
    &.ng-leave {
        opacity: 1;
        &.ng-leave-active {
            opacity: 0;
        }
    }
}

.at-view-fade-in.ng-enter {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
}

.at-view-fade-in.ng-enter-active {
    opacity: 1;
}

.at-view-fade-out.ng-leave {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;
}

.at-view-fade-out.ng-leave-active {
    opacity: 0;
}

.login-nav {
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 60px;
    ul {
        text-align: right;
        padding: 0 20px;

        li {
            display: inline-block;
            padding: 20px 5px;
            a {
                font-family: $sans-condensed;
                font-weight: 300;
                text-decoration: none;
                color: #666;
                font-weight: 100;
                text-transform: uppercase;
                text-rendering: geometricPrecision;
                -webkit-font-smoothing: antialiased;
            }
        }
    }
}
main {
    position: relative;
    > section {
        //margin-bottom:80px;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        &:before {
            height: 15%;
            top: -9.5%;
            @include transform(skewY(-2deg));
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 1;
            background: white;
        }
        &:after {
            height: 15%;
            bottom: -9.5%;
            @include transform(skewY(2deg));
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 1;
            background: white;
        }
        p {
            position: relative;
            z-index: 4;
            //margin: 60px;
        }
    }
}
.outer-container {
    @include outer-container;
    position: relative;
}
.block {
    @include outer-container;
    position: relative;
    z-index: 10;
}
.one-col {
    @include pad(4%);
}
.two-col {
    overflow: hidden;
    > div,
    > section {
        @include pad(4%);

        @include span-columns(6);
        &:nth-child(2) {
        }
    }
}
a {
    color: $red;
}
.two-col-text {
    margin: 60px;
    @include columns(2 8em);
    @include column-gap(4em);
}
.fit-vids {
    position: relative;
    z-index: 6;
}

.header-bg {
    position: absolute;
    height: 250px;
    width: 100%;
    z-index: 1;
    //background: blue;
    canvas {
        //width: 100%;
    }
}
.back-to {
    z-index: 4;
    @include position(fixed, 200px auto auto 0px);
    background-color: $red;
    color: white;
    text-align: left;
    width: 150px;
    height: 150px;
    @include transform(rotate(45deg));
    > div {
        @include transform(rotate(-45deg));
        margin: 30px 0 0 70px;
        text-align: left;
        @include position(absolute, -110px 0 0 24px);
        width: 300px;
    }
    a.back {
        display: block;
        width: 75px;
        height: 75px;
        //@include transform(rotate(45deg));
        background-color: white;
        text-decoration: none;
        @include position(absolute, 20px 0 0 -30px);
        span {
            display: block;
            //@extend %abs-mid;
            @include position(absolute, 23px 0 0 17px);
            @include transform(rotate(-45deg));
            font-size: 1.1rem;
            line-height: 1;
            font-family: "Open Sans Condensed", sans-serif;
            font-weight: 300;
            //-webkit-font-smoothing:antialiased;
            text-transform: uppercase;
            text-align: center;
        }
    }
    h3 {
        font-family: "Open Sans Condensed", sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        display: inline-block;
        background-color: $grey-dark;
        padding: 0 5px;
        float: left;
        clear: both;
    }
    h4 {
        font-size: 1rem;
        font-family: "Open Sans Condensed", sans-serif;
        font-weight: 300;
        display: inline-block;
        background-color: $grey-dark;
        padding: 0 5px;
        margin-bottom: 5px;
        float: left;
        clear: both;
    }
}
