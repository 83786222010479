.inspirl {
    .tech {
        background-image: url("../../images/inspirl/map.gif");
        padding: 4% 0;
        background-size: cover;
        background-attachment: fixed;
    }
    .video {
        background-color: #fa8a55;
        padding: 4% 0;
    }
}
