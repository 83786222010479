@import "../styles/variables.scss";
@import "./parellax.scss";
.hero.debug-on {
    margin: 0 auto;
    max-width: 1480px;
}
.thing-form {
    margin: 20px 0;
}

#banner {
    border-bottom: none;
    margin-top: -20px;
}

#banner h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hero-unit {
    position: relative;
    padding: 30px 15px;
    color: #f5f5f5;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    background: #4393b9;
}

.footer {
    text-align: center;
    padding: 30px 0;
    margin-top: 70px;
    border-top: 1px solid #e5e5e5;
}
.left {
    width: 100%;
    padding: 60px 5%;
    z-index: 5;
    //transform: translateY(50%);
    min-height: 607px;
    @media screen and (min-width: $break-medium) {
        position: absolute;
        padding: 60px 80px;
        width: 30%;
        top: 10%;
    }
    .me {
        width: 100%;
        max-width: 540px;
        //position: absolute;
        @media screen and (min-width: $break-medium) {
            width: 440px;
        }
    }
    .info {
        //position: absolute;
        width: 100%;
        left: 20px;
        h1 {
            position: absolute;
            padding: 0 10px;
            font-weight: 700;
            font-size: 52px;
            line-height: 58px;
            color: white;
            background-color: $grey-med;
            @media screen and (min-width: $break-medium) {
                font-size: 72px;
                line-height: 78px;
                top: 490px;
                left: 250px;
            }
        }
        .skill-list {
            position: absolute;
            //padding: 0 10px;
            top: 440px;
            box-sizing: border-box;
            color: white;
            width: 90%;
            font-size: 24px;
            @media screen and (min-width: $break-medium) {
                width: 510px;
                top: 580px;
                left: 190px;
            }
            a {
                text-decoration: none;

                -webkit-font-smoothing: antialiased;
                font-weight: 300;

                top: 50px;
                left: -50px;
                @media screen and (min-width: $break-medium) {
                    top: 70px;
                    position: absolute;
                    left: 20px;
                }
            }
            h2,
            li {
                font-weight: 300;
                font-size: 26px;
                -webkit-font-smoothing: antialiased;
                background-color: $grey-med;
                padding: 0 10px;
                @media screen and (min-width: $break-medium) {
                    font-size: 38px;
                }
            }
            h2 {
                //@include position(relative, auto auto auto 60px);
                @media screen and (min-width: $break-medium) {
                    @include position(relative, auto 0px auto);
                }
            }
            ul {
                list-style: none;
                display: block;
                //@include position(relative, -65px auto auto 30px );
                height: 58px;
                overflow: hidden;
                @media screen and (min-width: $break-medium) {
                    @include position(relative, -77px auto auto 110px);
                }
                li {
                    display: block;
                    position: absolute;
                    background-color: $red;
                    margin-left: 0px;
                }
            }
            .hide,
            .show {
                -webkit-transition: all linear 0.5s;
                -moz-transition: all linear 0.5s;
                -o-transition: all linear 0.5s;
                transition: all linear 0.5s;
                display: block !important;
            }
            .hide {
                opacity: 0;
            }
            .show {
                opacity: 1;
            }
        }
        h2 {
            display: inline-block;
            //position: absolute;
            //top: 220px;
            //left: 350px;
        }
        p {
            color: #666;
            position: absolute;
            top: 540px;
            left: 0px;
        }
    }
}
.fourth-hero {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 350px;
    left: 170px;
    z-index: 20;
    &.view-work {
        font-size: 22px;
        text-transform: uppercase;
        .small-diamond:hover {
            background-color: $grey-dark;
        }
    }
    @media screen and (min-width: $break-medium) {
        position: absolute;
        top: 210px;
        left: 470px;
    }
    .small-diamond {
        width: 80%;
        height: 80%;
        background-color: $red;
        @include transform(rotate(45deg));
        overflow: hidden;
        transition: background-color 0.5s;
        -webkit-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        -moz-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        > div {
            width: 160%;
            height: 160%;
            position: relative;
            top: -30%;
            left: -20%;
            @include transform(rotate(-45deg));
        }
    }
}
.button-large {
    line-height: 140px;
    font-family: $sans-condensed;
    font-weight: 300;
    //-webkit-font-smoothing:anti-aliased;
    display: block;
    padding: 20px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    text-decoration: none;
    &.view-work {
        font-size: 22px;
        text-transform: uppercase;
    }
}
.contact {
    &.profile {
        background-image: url("../images/jeffboulay.jpg");
        @include filter(contrast(90%) brightness(70%));
        width: 50%;
        height: 100%;
        position: fixed;
        right: 0;
        background-size: cover;
        z-index: 10;
    }
}
