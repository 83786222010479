//how the diamonds are rotated
$yrotation: -15deg;

.home.hero {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
/* Parallax base styles
  --------------------------------------------- */

.parallax {
    height: 500px; /* fallback for older browsers */
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-perspective: 300px;
    perspective: 300px;
    h1 {
        font-size: 13rem;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
}

.parallax__group {
    position: relative;
    height: 500px; /* fallback for older browsers */
    height: 160vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.parallax__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.parallax__layer--fore {
    @include transform(translate3d(-640px, 0, 400px) scale(0.7));
    height: 140%;
    z-index: 4;
    color: white;
    text-align: left;
    font-size: 70px;
}

.parallax__layer--base {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 4;
    height: 80%;
}

.parallax__layer--left {
    height: 100%;
    z-index: 4;

    z-index: 3;
}

.parallax__layer--right {
    width: 100%;
    z-index: 1;
}

/* Debugger styles - used to show the effect
  --------------------------------------------- */

.debug {
    position: fixed;
    top: 0;
    left: 0.5em;
    z-index: 999;
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 0.5em;
    border-radius: 0 0 5px 5px;
}
.debug-on .parallax__group {
    @include transform(translate3d(-1580px, 0, -1800px) rotateY(30deg));
}
.debug-on .parallax__layer {
    //box-shadow: 0 0 10px 2px #000;
    opacity: 0.9;
}
.parallax__group {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
}

/* demo styles
  --------------------------------------------- */

body,
html {
    //overflow: hidden;
}

body {
    font: 100% / 1.5 Arial;
}

* {
    margin: 0;
    padding: 0;
}

.parallax {
    font-size: 200%;
}

/* centre the content in the parallax layers */
.title {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* style the groups
  --------------------------------------------- */

.parallax {
    display: none;
    @media screen and (min-width: $break-medium) {
        display: block;
    }
    .diamond {
        width: 1200px;
        height: 1200px;
        @include transform(rotate(45deg));
        overflow: hidden;
        -webkit-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        -moz-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        > div {
            width: 160%;
            height: 160%;
            position: relative;
            top: -30%;
            left: -20%;
            @include transform(rotate(-45deg));
        }
    }
    .small-diamond {
        width: 1300px;
        height: 1300px;
        @include transform(rotate(45deg));
        overflow: hidden;
        -webkit-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        -moz-box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
        img {
            @extend %abs-mid;
            width: 50%;
        }
        h1 {
            @extend %abs-mid;
            color: white;
        }
        > div {
            width: 140%;
            height: 160%;
            position: relative;
            top: -30%;
            left: -20%;
            @include transform(rotate(-45deg));
        }
    }
}
.debug-on .parallax__group {
    &.first-hero {
        transform: translate3d(1000px, 0, -610px) rotateY($yrotation);
        &:hover {
            transform: translate3d(1000px, 0, -610px) rotateY(0deg);
            .small-diamond.dleft > div {
                background-color: $grey-dark;
            }
        }
        .diamond {
            > div {
                background: url("../images/work/ddsharkweek.jpg");
                background-size: cover;
            }
        }
        .parallax__layer--left {
            transform: translate3d(-770px, -1130px, -600px) scale(1);
        }
        .parallax__layer--right {
            transform: translate3d(-240px, 150px, 360px) scale(0.4);
        }
        .small-diamond {
            &.dleft {
                > div {
                    transition: background-color 0.5s;
                    background-color: $red;
                }
            }
            &.dmid {
                > div {
                    background-color: $grey-med;
                }
            }
            &.dright {
                > div {
                    background-color: $off-white;
                }
            }
        }
    }

    &.second-hero {
        transform: translate3d(420px, -420px, -610px) rotateY($yrotation);
        &:hover {
            transform: translate3d(720px, -420px, -610px) rotateY(0deg);
        }
        z-index: 2;
        .diamond {
            > div {
                background: url("../images/work/inspirl.jpg");
                background-size: cover;
            }
        }
        .parallax__layer--left {
            transform: translate3d(-1160px, -1000px, -400px) scale(0.9);
        }
        .parallax__layer--right {
            transform: translate3d(450px, -230px, 260px) scale(0.4);
        }
        .small-diamond {
            &.dleft {
                > div {
                    background-color: $off-white;
                    h1 {
                        color: $red;
                    }
                }
            }
            &.dmid {
                > div {
                    background-color: $grey-med;
                }
            }
            &.dright {
                > div {
                    background-color: $red;
                }
            }
        }
    }
    &.third-hero {
        transform: translate3d(850px, -840px, -610px) rotateY($yrotation);
        &:hover {
            transform: translate3d(850px, -840px, -610px) rotateY(0deg);
        }
        z-index: 2;
        .diamond {
            > div {
                background: url("../images/work/lm-rise.jpg");
                background-size: cover;
            }
        }
        .parallax__layer--left {
            transform: translate3d(-1160px, 950px, -400px) scale(0.9);
        }
        .parallax__layer--right {
            transform: translate3d(-120px, -230px, 550px) scale(0.2);
        }
        .small-diamond {
            &.dleft {
                > div {
                    background-color: $off-white;
                    h1 {
                        color: $red;
                    }
                }
            }
            &.dmid {
                > div {
                    background-color: $grey-med;
                }
            }
            &.dright {
                > div {
                    background-color: $red;
                }
            }
        }
    }
}

/* misc
  --------------------------------------------- */
.demo__info {
    position: absolute;
    z-index: 100;
    bottom: 1vh;
    top: auto;
    font-size: 80%;
    text-align: center;
    width: 100%;
}
