@import "../styles/bourbon/bourbon";
@import "../styles/variables.scss";
.work {
    .one-col {
        padding: 0;
    }
    .diamonds {
        @media screen and (max-width: 767px) {
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                overflow: hidden;
                position: relative;
                width: 100%;
                margin: 0;
                .content {
                    //@include transform(translateZ(0));
                    //-webkit-backface-visibility: hidden;
                    outline: 1px solid transparent;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    img {
                        //@extend %abs-mid;
                    }
                    h2 {
                        text-decoration: none;
                        color: white;
                        font-size: 1.2rem;
                        -webkit-font-smoothing: antialiased;
                        @include position(absolute, 85% 0px auto 0px);
                    }
                }

                a {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 400;
                    text-decoration: none;
                }
                .wrapper {
                    img {
                        position: relative;
                        width: 100%;
                    }
                }
                &:after {
                    content: " ";
                    box-sizing: border-box;
                    width: 106%;
                    @include position(absolute, auto 0 -10px 0px);
                    @include transform(rotate(-6deg));
                    height: 100px;
                    z-index: 300;
                    margin: -10px;
                    background-color: $red;
                    -webkit-transition: -webkit-transform 0.5s;
                    transition: all 0.5s;
                }
            }
        }
        .content img {
            @extend %abs-mid;
        }
    }
    @media screen and (min-width: $break-medium) {
        .diamonds {
            width: 1480px;
            margin: 180px 0 0 0px;
            li {
                list-style: none;
                width: 300px;
                float: left;
                position: relative;
                min-height: 300px;
                position: relative;
                margin-right: 124px;
                margin-top: -88px;
                &:hover div.wrapper {
                    @include transform(
                        perspective(1px) translate3d(0, 0, 0) rotate(45deg)
                            scale(1.2)
                    );
                    z-index: 100;
                    box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
                    &:after {
                        @include position(absolute, auto 0 -150px 0px);
                        @include transform(rotate(-35deg));
                    }
                }
                div.wrapper {
                    float: left;
                    width: 300px;
                    min-height: 300px;
                    overflow: hidden;
                    position: relative;
                    @include transform(rotate(45deg));
                    -webkit-transition: -webkit-transform 0.5s;
                    transition: all 0.5s;
                    z-index: 2;
                    box-shadow: 0 0 0 rgba(50, 50, 50, 0);
                    &:before {
                        content: " ";
                        border: 1px solid white;
                        box-sizing: border-box;
                        width: 280px;
                        position: absolute;
                        height: 280px;
                        z-index: 300;
                        margin: 10px;
                    }
                    &:after {
                        content: " ";
                        box-sizing: border-box;
                        width: 600px;
                        @include position(absolute, auto 0 -150px 0px);
                        @include transform(rotate(-55deg));
                        height: 280px;
                        z-index: 300;
                        margin: 10px;
                        background-color: $red;
                        -webkit-transition: -webkit-transform 0.5s;
                        transition: all 0.5s;
                    }
                    &:hover {
                        @include transform(
                            perspective(1px) translate3d(0, 0, 0) rotate(45deg)
                                scale(1.2)
                        );
                        z-index: 100;
                        box-shadow: 15px 11px 65px rgba(50, 50, 50, 0.59);
                    }
                    > img {
                        width: 450px;
                        position: absolute;
                        min-height: 400px;
                        left: -80px;
                        top: -80px;
                        @include transform(rotate(-45deg));
                    }
                }

                //-webkit-backface-visibility: hidden;

                &:nth-child(5n - 1) {
                    margin-left: 212px;
                }
                &:nth-child(11) {
                    margin-left: 423px;
                }
                .content {
                    //@include transform(translateZ(0));
                    //-webkit-backface-visibility: hidden;
                    outline: 1px solid transparent;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    img {
                        //@extend %abs-mid;
                    }
                    h2 {
                        text-decoration: none;
                        color: white;
                        font-size: 1.2rem;
                        -webkit-font-smoothing: antialiased;
                        @include position(absolute, 85% 0px auto 0px);
                    }
                }

                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 400;
                    text-decoration: none;
                    left: 0;
                }
            }
        }
    }
}
