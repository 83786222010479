@import "../styles/bourbon/bourbon";
@import "../styles/variables.scss";

.experiment-list {
    ul {
        list-style: none;
        margin: 0;
        li {
            @include span-columns(10);
            @include shift(1);
            margin-bottom: 80px;
            position: relative;
            height: 400px;
            &:nth-child(2n) {
                .caption {
                    @include omega();
                    padding-left: 2%;
                    right: 0;
                    left: initial;
                    &:before {
                        left: -10px;
                        right: initial;
                    }
                }
                .image-container {
                    left: 0;
                    right: initial;
                }
            }
        }
    }
    .caption {
        @include span-columns(4);
        position: absolute;
        height: 400px;
        padding: 20px;
        //margin: 10px;
        background-color: $grey-med;
        left: 0;
        z-index: 1;
        h3 {
            color: white;
        }
        p {
            position: relative;
            z-index: 3;
        }
        &:before {
            content: " ";
            width: 25px;
            height: 25px;
            display: block;
            @include position(absolute);
            background-color: $grey-med;
            right: -10px;
            @extend %mid;
            @include transform(rotate(45deg));
        }
    }
    .image-container {
        @include span-columns(8);
        @include omega();
        float: none;
        position: absolute;
        height: 400px;
        -webkit-background-size: cover;
        background-size: cover;
        right: 0;
        img {
            max-height: 100%;
            max-width: 100%;
            display: block;
            margin: auto;
        }
    }
}
