@import "../styles/variables.scss";
.main-nav {
    background-color: rgba(10, 10, 10, 0.9);
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    -webkit-transition: all linear 0.5s;
    transition: all ease-in 0.5s;
    //opacity: 100%;
    &.hide {
        opacity: 0;
        display: none;
    }
    .logo {
        width: 50px;
        @media screen and (min-width: $break-medium) {
            width: 75px;
        }
    }
    ul {
        text-align: center;
        padding: 0 20px;
        @extend %abs-mid;
        min-width: 600px;
        list-style: none;
        li {
            padding: 10px 5px;
            @media screen and (min-width: $break-medium) {
                padding: 20px 5px;
            }

            width: 100%;
            border-bottom: 1px solid $red;
            a {
                font-family: $sans-condensed;
                font-weight: 300;
                text-decoration: none;
                font-size: 20px;
                color: #fff;
                font-weight: 100;
                text-transform: uppercase;
                text-rendering: geometricPrecision;
                -webkit-font-smoothing: antialiased;
                @media screen and (min-width: $break-medium) {
                    font-size: 30px;
                }
            }
        }
    }
}

header {
    position: fixed;
    //top: -25px;
    left: 0;
    z-index: 30;
    > .logo {
        background-color: $grey-dark;
        position: absolute;
        padding: 5px;
        width: 100px;
        height: 300px;
        transform: translate(-170px, -90px) rotate(45deg);
        @include animation(logo-rotate-in 1.5s ease-out);
        //@include animation-delay(.5s);
        @include animation-iteration-count(1);
        transform-origin: bottom right;
        img {
            float: right;
            margin-top: 40px;
            transform: rotate(-45deg);
        }
    }
    .nav-button {
        background-color: $red;
        position: absolute;
        padding: 5px;
        z-index: 4;
        width: 100px;
        height: 200px;
        transform: translate(-100px, -20px) rotate(20deg);
        //@include animation-name(rotate-in);
        @include animation(menu-rotate-in 1s ease-out);
        //@include animation-delay(.3s);
        @include animation-iteration-count(1);

        transform-origin: bottom right;
        cursor: pointer;
        img,
        button {
            background: url("../images/menu.svg") no-repeat center center;
            width: 35px;
            height: 25px;
            border: none;
            overflow: hidden;
            text-indent: -600%;
            float: right;
            margin-top: 25px;
            margin-right: 5px;
            transform: rotate(-20deg);
        }
    }
}

//animations
@include keyframes(menu-rotate-in) {
    0%,
    50% {
        @include transform(translate(-100px, -20px) rotate(0));
    }
    100% {
        @include transform(translate(-100px, -20px) rotate(20deg));
    }
}
@include keyframes(logo-rotate-in) {
    0%,
    50% {
        transform: translate(-170px, -90px) rotate(0deg);
    }
    100% {
        transform: translate(-170px, -90px) rotate(45deg);
    }
}
