@import "../../styles/bourbon/bourbon";
@import "../../styles/neat/neat";
.rise {
    &.wd-hero {
        .rise-hero {
            position: relative;
            z-index: 2;
        }
        .rise-logo {
            position: absolute;
            @include transform(scale(0.8));
        }
    }
    h1 {
        margin-top: -40px;
        position: relative;
        z-index: 2;
    }
    .mountain {
        padding: 4% 0;
        background-image: url("../../images/rise/bg-header-large.jpg");
        background-size: cover;
        background-attachment: fixed;
    }
    .gallery {
    }
}
