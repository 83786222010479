.wd-hero{
	&.tvnext-hero{
		//height: 870px;
		background-size: contain;
		background:#000000 top center no-repeat;
	}

	.t-hero{
		position: absolute;
		z-index: 4;
		top: 35px;
		left: 0;
	}
}